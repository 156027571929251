import React, { useState, useEffect, useContext } from 'react'
import { Heading2, Heading3 } from '../../styles/vars/textStyles.style'
import AnimateFadeIn from '../animation/AnimateFadeIn'
import Button from '../Button'

// Components
import CaseStudyItem from '../CaseStudyItem/index'
import CaseStudyModal from '../CaseStudyModal/index'
// import DividingLine from '../DividingLine'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import RichText from '../RichText'
import ScrollSection from '../ScrollSection'

import {
  TextSection,
  CaseStudyList,
  ShowAllCaseStudies,
  TextWrap,
} from './index.style'

// Context
import { StoreDispatch } from '../../Store'

const TextList = ({ home, subheading, heading, button, useCases, last }) => {
  const dispatch = useContext(StoreDispatch)

  const colourArray = ['#cc9f68', '#c88885', '#be9cc1', '#92a8bc', '#92babc']

  const [modalTitle, setModalTitle] = useState(null),
    [modalContent, setModalContent] = useState(null),
    [modalOpen, setModalOpen] = useState(false)

  const sectionDelay = 0.5

  useEffect(() => {
    if (modalOpen) {
      if (window.innerWidth <= 1024) {
        // if (
        //   !document.querySelector('body').classList.contains('disable-scroll')
        // ) {
        document.querySelector('body').classList.add('disable-scroll')
        // }
      } else {
        if (window?.scroll?.stop) {
          window.scroll.stop()
        }
      }
    } else {
      if (window.innerWidth <= 1024) {
        if (
          document.querySelector('body').classList.contains('disable-scroll')
        ) {
          document.querySelector('body').classList.remove('disable-scroll')
        }
      } else {
        if (window?.scroll?.update) {
          window.scroll.update()
          window.scroll.start()
        }
      }
    }
  }, [modalOpen])

  return (
    <>
      {home ? (
        <ScrollSection>
          <TextSection home>
            <Grid>
              <GridItem
                mobile={14}
                tabletL={12}
                tabletLStart={3}
                desk={8}
                deskStart={3}
              >
                <AnimateFadeIn delay={sectionDelay}>
                  <Heading3 as={`h3`}>{subheading}</Heading3>
                  <Heading2>{heading}</Heading2>
                  <Button
                    text={button.buttonText}
                    to={button.buttonLink}
                    theme={3}
                    hasborder
                  />
                </AnimateFadeIn>
              </GridItem>
              <GridItem mobile={16} tabletL={12} tabletLStart={3}>
                <AnimateFadeIn delay={sectionDelay * 4}>
                  <Heading3>Featured use cases</Heading3>
                  <CaseStudyList itemcount={useCases.length} home>
                    {useCases.map((useCase, key) => {
                      return (
                        <CaseStudyItem
                          key={key}
                          typeOfUseCase={useCase.isThisSupportedOrUnsupported}
                          title={useCase.useCaseTitle}
                          fullContent={useCase.useCaseContent}
                          event={() => {
                            setModalTitle(useCase.useCaseTitle)
                            setModalContent(useCase.useCaseContent)
                            setModalOpen(true)

                            dispatch({ type: 'CIRCLE_GROW' })
                            dispatch({ type: 'HALF_SHOW_MODAL' })
                          }}
                          theme={colourArray[4]}
                        />
                      )
                    })}
                  </CaseStudyList>
                  <ShowAllCaseStudies>
                    <Button
                      to="/guidelines"
                      text={`View all ethical guidelines`}
                      hasborder
                      theme={4}
                    />
                  </ShowAllCaseStudies>
                </AnimateFadeIn>
              </GridItem>
            </Grid>
          </TextSection>
        </ScrollSection>
      ) : (
        <ScrollSection>
          <TextSection id={useCases.groupTitle} useCasePage>
            <Grid>
              <GridItem
                mobile={14}
                tabletL={12}
                tabletLStart={3}
                desk={useCases.groupCopy ? 6 : 8}
                deskStart={3}
              >
                {' '}
                <AnimateFadeIn delay={sectionDelay}>
                  <Heading2>{useCases.groupTitle}</Heading2>
                </AnimateFadeIn>
              </GridItem>
              {useCases.groupCopy ? (
                <GridItem
                  mobile={14}
                  tabletL={12}
                  tabletLStart={3}
                  desk={5}
                  deskStart={9}
                >
                  <AnimateFadeIn delay={sectionDelay}>
                    <TextWrap>
                      <RichText content={useCases.groupCopy} />
                    </TextWrap>
                  </AnimateFadeIn>
                </GridItem>
              ) : (
                ``
              )}
              <GridItem mobile={16} tabletL={12} tabletLStart={3}>
                <AnimateFadeIn delay={sectionDelay}>
                  <CaseStudyList
                    itemcount={useCases.groupUseCases.length}
                    last={last}
                  >
                    {useCases.groupUseCases.map((useCase, key) => {
                      return (
                        <CaseStudyItem
                          key={key}
                          typeOfUseCase={useCase.isThisSupportedOrUnsupported}
                          title={useCase.useCaseTitle}
                          fullContent={useCase.useCaseContent}
                          event={() => {
                            setModalTitle(useCase.useCaseTitle)
                            setModalContent(useCase.useCaseContent)
                            setModalOpen(true)

                            if (window.innerWidth <= 1024) {
                              document
                                .querySelector('body')
                                .classList.add('disable-scroll')
                            }

                            dispatch({ type: 'CIRCLE_GROW' })
                            dispatch({ type: 'HALF_SHOW_MODAL' })
                          }}
                          theme={colourArray[2]}
                        />
                      )
                    })}
                  </CaseStudyList>
                </AnimateFadeIn>
              </GridItem>
            </Grid>
          </TextSection>
        </ScrollSection>
      )}

      <CaseStudyModal
        modalOpen={modalOpen}
        modalTitle={modalTitle}
        modalContent={modalContent}
        setModalOpen={setModalOpen}
      />
      {/* <ScrollSection>
        <DividingLine />
      </ScrollSection> */}
    </>
  )
}

export default TextList
