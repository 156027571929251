import React from 'react'

const Arrow = ({
  width = 35,
  height = 35,
  stroke = '#353535',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 35"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <circle
        opacity="0.2"
        cx="17.5"
        cy="17.5"
        r="17"
        stroke={stroke}
        fill={`none`}
      />
      <line x1="10" y1="17.4545" x2="23" y2="17.4545" stroke={stroke} />
      <line
        x1="19.802"
        y1="21.5837"
        x2="23.6202"
        y2="17.1292"
        stroke={stroke}
      />
      <line
        x1="23.6204"
        y1="17.7799"
        x2="19.8022"
        y2="13.3254"
        stroke={stroke}
      />
    </svg>
  )
}

export default Arrow
