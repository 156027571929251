import React from 'react'
import { TextBodyBold } from '../../styles/vars/textStyles.style'

// Components

// SVG
import Arrow from '../svgs/Arrow'

import { CaseStudyItem, CaseStudyBtn, SmallText, Title } from './index.style'

const Section = ({ typeOfUseCase, title, event, theme }) => {
  return (
    <CaseStudyItem theme={theme}>
      <CaseStudyBtn
        onClick={() => {
          event()
        }}
      >
        <SmallText>{typeOfUseCase + ` use case`}</SmallText>
        <Title>
          <TextBodyBold>{title}</TextBodyBold>
        </Title>
        <Arrow />
      </CaseStudyBtn>
    </CaseStudyItem>
  )
}

export default Section
