import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'

export const CaseStudyItem = styled.li`
  border: 0.1rem solid ${colors.dark};
  border-radius: 0.5rem;
  display: block;
  margin-bottom: 1.5rem;
  overflow: hidden;
  position: relative;

  transition: border-color 1s ${easings.modal.default};

  &:hover {
    ${({ theme }) => {
      return theme
        ? `border: 0.1rem solid ${theme};`
        : `border: 0.1rem solid #be9cc1;`
    }}
  }

  ${mq.tabletL} {
    margin-bottom: 0;
  }

  &:before {
    ${({ theme }) => {
      return theme === 'blue'
        ? `
          background: linear-gradient(#B7D2EC  0%,
#B7E9EC 100%);
        `
        : ``
    }}
    bottom: 0;
    content: '';
    opacity: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.5s ease;
    z-index: -1;
  }

  &:hover {
    &:before {
      /* opacity: 1; */
    }
  }

  svg {
    display: inline-block;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);

    ${mq.desk} {
      display: none;
    }
  }
`

export const CaseStudyBtn = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  padding: 1.5rem;
  text-align: left;
  width: 100%;
  color: ${colors.dark};
  transition: background-color 1s ${easings.modal.default};

  &:hover {
    background: ${colors.light};
  }

  ${mq.tabletL} {
    padding: 3rem;
  }

  ${mq.desk} {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
`

export const SmallText = styled.p`
  font-size: 0.9rem;
  font-style: italic;
  margin-bottom: 1.3rem;

  ${mq.desk} {
    font-size: 1.2rem;
    margin-bottom: 0;
    position: absolute;
    right: 2rem;
    top: 1rem;
  }
`
export const Title = styled.div`
  font-family: ${font.secondary.family};
  /* font-size: 1.5rem; */
  max-width: 70%;

  ${mq.desk} {
    max-width: 55%;
  }
`
