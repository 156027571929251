import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { Button } from '../Button/index.style'

export const HeroSection = styled.div`
  padding-top: 11.7rem;
  position: relative;
  text-align: center;
  padding-bottom: 10rem;

  ${mq.tabletL} {
    padding-bottom: 16rem;
    padding-top: 12rem;
  }

  ${mq.desk} {
    padding-bottom: 26rem;
    padding-top: 16vmin;
  }
`
export const Explore = styled.div`
  padding-top: 21.4rem;
  margin-bottom: 6rem;
  text-align: center;

  ${mq.tabletL} {
    display: none;
  }

  img {
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    border-bottom: none;
    font-family: ${font.secondary.family};
    text-transform: uppercase;
    text-decoration: none;
  }
`

export const List = styled.ul`
  display: none;

  ${mq.tabletL} {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`

export const ButtonItem = styled.li`
  display: inline-block;
  margin-right: 1rem;

  ${Button} {
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
`

export const Wrap = styled.div`
  margin-left: -2.2rem;
  margin-right: -2.2rem;
  overflow: hidden;

  ${mq.tabletL} {
    display: none;
  }
`

export const MobileList = styled.ul`
  background: transparent;
  list-style-type: none;
  margin-bottom: 8rem;
  max-width: 100%;
  padding-left: 2.2rem;
  padding-bottom: 4rem;
  position: relative;
  text-transform: uppercase;
  overflow-x: auto;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;

  ${mq.tabletL} {
    display: none;
  }
`
export const MobileItemLi = styled.li`
  border: 0.1rem solid ${colors.dark};
  border-radius: 10rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 1rem;
  min-width: fit-content;
  padding: 1.1rem 1.5rem;
  white-space: nowrap;
`

export const MobileItem = styled.span``
