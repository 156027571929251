import React from 'react'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { Heading2 } from '../../styles/vars/textStyles.style'

// Components
import Button from '../Button'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import BackArrow from '../WellbeingModules/BackArrow'

// import Gif from './assets/hero.gif'

import {
  HeroSection,
  ButtonItem,
  List,
  // MobileList,
  // MobileItemLi,
  // Wrap,
} from './index.style'
import AnimateFadeIn from '../animation/AnimateFadeIn'
import styled from 'styled-components'

gsap.registerPlugin(ScrollToPlugin)

const Hero = ({ home, title, errorPage, headings }) => {
  const sectionDelay = 0.5

  // const update = (e, heading) => {
  //   // console.log(heading)
  //   let nextSection = document.getElementById(heading.groupTitle)
  //   if (window?.scroll?.scrollTo) {
  //     window.scroll.scrollTo(nextSection)
  //   } else {
  //     gsap.to(window, { scrollTo: nextSection })
  //   }
  // }

  return (
    <HeroSection errorPage={errorPage}>
      <Grid>
        <GridItem mobile={12} mobileStart={3} tabletL={6} tabletLStart={6}>
          <AnimateFadeIn delay={sectionDelay}>
            <FlexContainer>
              <BackArrow/>
              <Heading2 as={`h1`}>
                {title ? title : `An initiative to steer the future of AI`}
              </Heading2>
            </FlexContainer>
          </AnimateFadeIn>
        </GridItem>
        <GridItem
          mobile={16}
          tabletL={14}
          tabletLStart={2}
          deskL={10}
          deskLStart={4}
        >
          {/* <Wrap>
            <AnimateFadeIn delay={sectionDelay}>
              <MobileList
                ref={selectRef}
              >
                {headings.map((heading, key) => {
                  return (
                    <MobileItemLi
                      key={key}
                      onClick={e => {
                        update(e, heading)
                      }}
                    >
                      {heading.groupTitle.toString()}
                    </MobileItemLi>
                  )
                })}
              </MobileList>
            </AnimateFadeIn>
          </Wrap> */}
          <List>
            <AnimateFadeIn delay={sectionDelay}>
              {headings.map((heading, key) => {
                return (
                  <ButtonItem key={key}>
                    <Button
                      text={heading.groupTitle}
                      to="/"
                      hasborder
                      theme={0}
                      event={e => {
                        let nextSection = document.getElementById(
                          heading.groupTitle
                        )
                        if (window?.scroll?.scrollTo) {
                          window.scroll.scrollTo(nextSection)
                        } else {
                          gsap.to(window, { scrollTo: nextSection })
                        }
                      }}
                    >
                      {heading.groupTitle}
                    </Button>
                  </ButtonItem>
                )
              })}
            </AnimateFadeIn>
          </List>
        </GridItem>
      </Grid>
    </HeroSection>
  )
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default Hero
