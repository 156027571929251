import styled from 'styled-components'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import {
  Heading2,
  Heading3,
  TextBody,
} from '../../styles/vars/textStyles.style'
import { Button } from '../Button/index.style'

export const TextSection = styled.div`
  ${({ layout }) => {
    return layout === 'centered' ? `text-align: center;` : ``
  }}
  ${({ useCasePage }) => {
    return useCasePage === true
      ? `&:after {
        background: rgba(53,53,53,.3);
        bottom: 0;
        content: '';
        display: inline-block;
        height: .1rem;
        left: 0;
        opacity :0;
        position: absolute;
        right: 0;
        transition: opacity 1s ${easings.modal.default};

        [data-inview='true'] & {
          opacity: 1;
        }
      }`
      : ``
  }}

  padding-top: 9rem;

  ${mq.desk} {
    padding-top: 14rem;
  }

  ${mq.deskL} {
    padding-top: 20rem;
  }

  ${({ home }) => {
    return home === true
      ? `
        padding-top: 15vmin;
        margin-bottom: 20vmin;

        ${mq.desk} {
        padding-top: 10vmin;
        margin-bottom: 25vmin;
      }

      ${mq.deskL} {
        margin-bottom:  30rem;
        padding-top: 20rem;
      }
  }`
      : `margin-bottom: 2rem;`
  }}

  ${Heading3} {
    margin-top: 10rem;
  }

  ${Heading2} {
    margin-bottom: 6rem;
  }

  ${TextBody} {
    margin-bottom: 6rem;
  }

  ${Button} {
    margin-top: 7rem;
  }
`

export const CaseStudyList = styled.ul`
  list-style-type: none;

  ${({ home }) => {
    return home === true
      ? `
  padding-bottom: 0rem;`
      : `padding-bottom:8rem;
  ${mq.tabletL} {
    padding-bottom: 22.4rem;
  }`
  }}

  ${mq.tabletL} {
    ${({ itemcount }) => {
      return itemcount === 1
        ? `
    display: grid;
    grid-template-columns: 1;
    gap: 2.6rem;`
        : `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.6rem;

     li {
    &:nth-of-type(2n-1) {
        margin-right: 0.5rem;
      }
  }
    `
    }}
  }
`

export const ShowAllCaseStudies = styled.div`
  ${mq.tabletL} {
    text-align: right;
  }
`

export const TextWrap = styled.div`
  ${mq.tabletL} {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
`
