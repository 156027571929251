import React from 'react'
import { graphql } from 'gatsby'

// Components

import DividingLine from '../components/DividingLine'
import Footer from '../components/Footer'
import TextList from '../components/TextList'
import TextRepeater from '../components/TextRepeater'
import Seo from '../components/Seo'
import ScrollSection from '../components/ScrollSection'
import UseCasesHero from '../components/UseCasesHero'

const UseCasesPage = ({ data }) => {
  const templateData = data.contentfulPageUseCases

  return (
    <>
      <Seo
        title={
          templateData.seoTitle ? templateData.seoTitle : templateData.heroTitle
        }
        description={templateData.description}
      />
      <ScrollSection>
        <>
          <UseCasesHero
            title={templateData.heroTitle}
            headings={templateData.useCaseSection}
          />
          <DividingLine />
        </>
      </ScrollSection>
      {templateData.useCaseSection.map((useCaseSection, key) => {
        return (
          <TextList
            useCases={useCaseSection}
            key={key}
            last={key === templateData.useCaseSection.length - 1}
          />
        )
      })}
      <TextRepeater
        repeaterContent={templateData.text}
        start={templateData.rightOrLeftAlignedText}
        button={templateData.button}
        bigHeading
      />
      <ScrollSection>
        <Footer />
      </ScrollSection>
    </>
  )
}

export default UseCasesPage

export const pageQuery = graphql`
  query pageUseCasesBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPageUseCases(slug: { eq: $slug }) {
      ...useCasesQuery
    }
  }
`
