import React, { useRef, useEffect, useContext } from 'react'
// import FocusTrap from 'focus-trap-react'

import { Heading2 } from '../../styles/vars/textStyles.style'

// Components
import Container from '../Container/index'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import RichText from '../RichText'
import Portal from '../Portal'

import {
  SectionDiv,
  ModalInner,
  ModalGradient,
  Close,
  CloseButton,
  Wrap,
} from './index.style'

// Context
import { StoreDispatch } from '../../Store'

const Section = ({ modalOpen, modalTitle, modalContent, setModalOpen }) => {
  const $modal = useRef()
  const dispatch = useContext(StoreDispatch)

  useEffect(() => {
    const checkKey = e => {
      if (modalOpen) {
        if (e.key === 'Escape' || e.code === 'Escape') {
          setModalOpen(false)
          dispatch({ type: 'CIRCLE_SHRINK' })
          dispatch({ type: 'HIDE_MODAL' })
        }
      }
    }

    window.addEventListener('keyup', checkKey)

    return () => {
      window.removeEventListener('keyup', checkKey)
    }
  }, [modalOpen, setModalOpen, dispatch])

  useEffect(() => {
    if (modalOpen) {
      $modal.current.scrollTop = 0
    }
  }, [modalOpen])

  return (
    <div>
      <Portal>
        <div>
          <SectionDiv
            modalOpen={modalOpen}
            onClick={() => {
              setModalOpen(false)
              dispatch({ type: 'CIRCLE_SHRINK' })
              dispatch({ type: 'HIDE_MODAL' })
            }}
            tabIndex={modalOpen ? 0 : -1}
            aria-live="polite"
          >
            <Container>
              <Grid>
                <GridItem
                  mobile={16}
                  tabletL={10}
                  tabletLStart={4}
                  deskL={8}
                  deskLStart={5}
                >
                  <Wrap
                    modalOpen={modalOpen}
                    onClick={e => e.stopPropagation()}
                    tabIndex={modalOpen ? 0 : -1}
                  >
                    <ModalInner ref={$modal}>
                      <CloseButton>
                        <Close
                          tabIndex={modalOpen ? 0 : -1}
                          onClick={() => {
                            setModalOpen(false)
                            dispatch({ type: 'CIRCLE_SHRINK' })
                            dispatch({ type: 'HIDE_MODAL' })
                          }}
                        >
                          Close
                        </Close>
                      </CloseButton>

                      <Heading2 as={`h2`}>{modalTitle}</Heading2>
                      {modalContent && (
                        <RichText content={modalContent} small />
                      )}
                    </ModalInner>

                    <ModalGradient modalOpen={modalOpen} />
                  </Wrap>
                </GridItem>
              </Grid>
            </Container>
          </SectionDiv>
        </div>
      </Portal>
    </div>
  )
}

export default Section
