import styled from 'styled-components'
import { setVh } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { TextBody, TextBodyBold } from '../../styles/vars/textStyles.style'
import { ContainerMain } from '../Container/index.style'
import { RichTextMain } from '../RichText/index.style'

export const SectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${setVh('height', 100, true)}
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s ${easings.modal.default};
  transition-delay: ${props => (props.modalOpen ? '0s' : '0.5s')};
  z-index: 15;
  opacity: ${props => (props.modalOpen ? 1 : 0)};
  pointer-events: ${props => (props.modalOpen ? undefined : 'none')};

  ${ContainerMain} {
    opacity: ${props => (props.modalOpen ? 1 : 0)};
  }
`

export const Wrap = styled.div`
  transition: opacity 0.5s ${easings.modal.default};

  ${({ modalOpen }) => {
    return modalOpen === false
      ? `opacity: 0;
  pointer-events: none;
  transition-delay: .5s;`
      : `opacity: 1;
  pointer-events: all;
  transition-delay: 0.5s;`
  }}
`
export const ModalInner = styled.div`
  background: ${colors.light};
  border-radius: 1rem;
  bottom: auto;
  ${setVh('max-height', 90, true)}
  left: auto;
  overflow-y: scroll;
  padding: 0 3.9rem;
  position: relative;
  right: auto;
  top: auto;

  ${mq.tabletL} {
    bottom: auto;
    left: auto;
    padding: 0rem calc((100% / 16) * 3);
    position: relative;
    right: auto;
    top: auto;
  }

  h2 {
    padding-bottom: 4rem;
    position: relative;

    &:after {
      background-color: ${colors.dark};
      bottom: 0;
      content: '';
      height: 0.1rem;
      left: 0;
      opacity: 0.12;
      position: absolute;
      right: 0;
      bottom: 0;

      ${mq.tabletL} {
        left: calc((100% / -16) * 4);
        right: calc((100% / -16) * 4);
      }
    }
  }

  p {
    &:not(:last-of-type) {
      margin-bottom: 4rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  li {
    ${TextBodyBold},
    ${TextBody} {
      margin-bottom: 0;
    }
  }

  ${RichTextMain} {
    padding-bottom: 7rem;

    ${mq.tabletL} {
      padding-bottom: 14rem;
    }
  }
`

export const ModalGradient = styled.div`
  background: linear-gradient(rgba(253, 245, 237, 0) 0%, ${colors.light} 50%);
  border-radius: 1rem;
  height: 7rem;
  left: 2.2rem;
  pointer-events: none;
  position: absolute;
  right: 2.2rem;
  bottom: 0;

  ${mq.tabletL} {
    bottom: auto;
    height: 14rem;
    left: 0;
    position: absolute;
    right: 0;
    top: calc(100% - 14rem);
  }
`

export const CloseButton = styled.div`
  background: ${colors.light};
  margin-bottom: 4.4rem;
  padding-bottom: 2.2rem;
  padding-top: 3.9rem;
  position: sticky;
  top: 0rem;
  z-index: 2;

  ${mq.tabletL} {
    margin-right: calc((100% / 16) * -3);
    text-align: right;
  }
`
export const Close = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  font-family: ${font.secondary.family};
  font-size: 1.4rem;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;

  ${mq.tabletL} {
    margin-right: 0;
  }
`
